/*
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-11 15:05:59
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/main.js
 */
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import mixin from "./mixin"
import VueClipboard from "vue-clipboard2"

import ElementUI from "element-ui"
import "./assets/founderui.skyblue.css"
import "./assets/css/reset.css"
import "./assets/css/sprite.scss"
import "./assets/css/common.scss"

Vue.mixin(mixin)
Vue.config.productionTip = false
Vue.use(ElementUI).use(VueClipboard)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
